import { template as template_e9775acad4ed457499c0d92673a6b4e2 } from "@ember/template-compiler";
const FKText = template_e9775acad4ed457499c0d92673a6b4e2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
