import { template as template_8fa08cd5deab4e02a19d0dfc44887e63 } from "@ember/template-compiler";
const WelcomeHeader = template_8fa08cd5deab4e02a19d0dfc44887e63(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
